import { render, staticRenderFns } from "./ClientPopup.vue?vue&type=template&id=408f93cb&scoped=true"
import script from "./ClientPopup.vue?vue&type=script&lang=js"
export * from "./ClientPopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408f93cb",
  null
  
)

export default component.exports