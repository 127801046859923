<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      :width="40"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container>
        <v-row class="mt-5 mb-0" no-gutters>
          <v-col cols="12">
            <v-select
                v-model="currentItem.type"
                label="Client Type"
                :items="clientTypes"
                :prepend-inner-icon="clientIcon"
                @change="getIcon()"
                item-text="text"
                item-value="value"
                dense
                outlined
            />
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
                v-if="!isExists"
                v-model="currentItem.email"
                :rules="emailRules"
                label="Email"
                type="email"
                prepend-inner-icon="mdi-email"
                required
                class="mr-2"
                outlined
                dense
            />
            <g-p-auto-complete
                v-if="isExists"
                v-model="currentItem.email"
                apiPath="sales-counter/get-all-email"
                item-text="email"
                item-value="email"
                search-label="Select a Email"
                inner-icon="mdi-email"
                @clearSearchResult="handleClearSearchResult"
                @selectSearchResult="onSelectionsEmailHandler"
                class="mr-2"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
                class="mt-1"
                v-model="isExists"
                label="Is Exists?"
            />
          </v-col>
          <template v-if="currentItem.type === 2">
            <v-col cols="12">
              <v-text-field
                  v-model.number="currentItem.price"
                  :rules="numberRule"
                  class="right-align-text"
                  label="Price"
                  onclick="this.select()"
                  prepend-inner-icon="payments"
                  required
                  type="number"
                  dense
                  outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model.number="currentItem.linkPrice"
                  :rules="numberRule"
                  class="right-align-text"
                  label="Link Price"
                  onclick="this.select()"
                  prepend-inner-icon="payments"
                  required
                  type="number"
                  dense
                  outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model.number="currentItem.adultPrice"
                  :rules="numberRule"
                  class="right-align-text"
                  label="Other Price"
                  onclick="this.select()"
                  prepend-inner-icon="payments"
                  required
                  type="number"
                  dense
                  outlined
              />
            </v-col>
          </template>
          <v-col cols="12">
            <v-text-field
                v-model.number="currentItem.discount"
                :rules="numberRule"
                class="right-align-text"
                label="Percentage of Discount"
                onclick="this.select()"
                prepend-inner-icon="%"
                type="number"
                dense
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model.number="currentItem.name"
                label="Name"
                onclick="this.select()"
                prepend-inner-icon="mdi-account"
                required
                dense
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model.number="currentItem.phone"
                label="Phone"
                onclick="this.select()"
                prepend-inner-icon="mdi-cellphone"
                dense
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model.number="currentItem.skype"
                label="Skype"
                onclick="this.select()"
                prepend-inner-icon="mdi-skype-business"
                dense
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model.number="currentItem.whatsapp"
                label="Whatsapp"
                onclick="this.select()"
                prepend-inner-icon="mdi-whatsapp"
                dense
                outlined
            />
          </v-col>
          <v-col cols="12">
            <static-autocomplete
                :item-value="currentItem.country"
                @onChangeHandler="countrySelectionHandler"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model.number="currentItem.address"
                label="Address"
                onclick="this.select()"
                dense
                outlined
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <strong>Is Client Blocked?</strong>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.isBlocked"
                    color="primary"
                    dense
                    inset
                    prefix="Status"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model.number="currentItem.description"
                label="Write a reason why you want to block this client"
                onclick="this.select()"
                dense
                outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import GPAutoComplete from "../GPAutoComplete";
import StaticAutocomplete from "../StaticAutocomplete";

export default {
  name: 'ClientPopup',
  components: {
    StaticAutocomplete,
    GPAutoComplete,
    BasePopup
  },
  mixins: [PopupMixin],
  data() {
    return {
      isExists: false,
      isBlocked: false,
      clientTypes: [
        {
          text: 'Fix Clients',
          value: 2
        },
        {
          text: 'Special Clients',
          value: 3
        },
        {
          text: 'More Special Clients',
          value: 4
        },
        {
          text: 'Agency Clients',
          value: 5
        },
        {
          text: 'Buyers',
          value: 6
        },
        {
          text: "Others",
          value: 7
        },
      ],
      clientIcon: "mdi-account-group"
    }
  },
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Client'
          : 'Add Client'
    },
  },
  methods: {
    onSelectionsEmailHandler(selection) {
      this.currentItem.email = selection.email;
    },
    getIcon() {
      let type = this.currentItem.type;
      if(type === 1) {
        this.clientIcon = "mdi-shield-star";
      } else if (type === 2){
        this.clientIcon = "mdi-alpha-a-circle";
      } else if (type === 4){
        this.clientIcon = "mdi-star-three-points";
      }else if (type === 5){
        this.clientIcon = "mdi-alpha-b-circle";
      } else {
        this.clientIcon = "mdi-account-group";
      }
    }
  }
}
</script>

<style scoped>
</style>
